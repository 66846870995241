import { AttendanceSummary } from "src/types";

export function PersonClassAttendancesPanel(attendances: AttendanceSummary) {
  const multiusePassIds = Array.from(
    new Set(
      attendances.recent
        .filter(
          (attendance) => attendance.pass?.id && attendance.pass_is_multiuse
        )
        .map((attendance) => attendance.pass?.id)
    )
  );

  const getPassIdxClass = (passId: string) => {
    const index = multiusePassIds.indexOf(passId);
    if (index >= 0) {
      return "tinge" + index.toString();
    }
  };

  return (
    <table>
      <thead>
        <tr>
          <th>Date</th>
          <th>Attended</th>
          <th>Payment Detail</th>
        </tr>
      </thead>
      <tbody>
        {attendances.recent.map((attendance) => (
          <tr>
            <td>{attendance.date?.toDate().toLocaleDateString("en-AU")}</td>
            <td>{attendance.is_present ? "✓" : "✗"}</td>
            {attendance.pass_code ? (
              <td>
                <span class={getPassIdxClass(attendance.pass?.id || "")}>
                  {attendance.pass_name} ({attendance.pass_code})
                </span>
              </td>
            ) : (
              <td class="payment-pending">Unpaid</td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
