import {
  DocumentReference,
  Timestamp,
  and,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  or,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useState } from "preact/hooks";
import { db } from "../../../../firebase";
import { Pass, Person } from "../../../../types";
import { TimestampMax, TimestampMaxYear } from "../../../../utils/types";
import { Button } from "@mui/material";
import { parseAUDate } from "../../../../utils/datetime";

interface PassWithPerson extends Pass {
  person: Person;
  updating: boolean;
}

export default function AdminActivePasses() {
  const [activePasses, setActivePasses] = useState<PassWithPerson[]>();
  const now = new Date();
  const warnFrom = new Date();
  warnFrom.setDate(warnFrom.getDate() + 14);

  if (!activePasses) {
    const since = new Date();
    since.setDate(since.getDate() - 30);
    getDocs(
      query(
        collectionGroup(db, "passes"),
        and(
          where("has_remaining_uses", "==", true),
          or(
            where("expires", ">", Timestamp.fromDate(since)),
            where("expires", "==", Timestamp.fromMillis(0))
          )
        ),
        orderBy("expires", "desc"),
        orderBy("remaining_uses", "desc")
      )
    ).then((snapshot) => {
      Promise.all(
        snapshot.docs.map((doc) =>
          getDoc(doc.ref.parent.parent as DocumentReference)
        )
      ).then((peopleRefs) => {
        setActivePasses(
          snapshot.docs.map((doc, i) => {
            return {
              ...doc.data(),
              collectionPath: doc.ref.path,
              person: peopleRefs[i].data(),
            } as PassWithPerson;
          })
        );
      });
    });

    return (
      <div class="app-body-wrap">
        <div class="app-body">Loading...</div>
      </div>
    );
  }

  const updateActive = (passIdx: number, pass: PassWithPerson) => {
    setActivePasses((activePasses) => {
      if (!activePasses) {
        return;
      }
      return [
        ...activePasses.slice(0, passIdx),
        pass,
        ...activePasses.slice(passIdx + 1),
      ];
    });
  };

  const updateExpiries = (path: string, newExpiry: Timestamp) => {
    const passIdx = activePasses.findIndex(
      (pass) => pass.collectionPath === path
    );
    if (passIdx < 0) {
      alert("Error updating pass, not found?");
      return;
    }
    const pass = activePasses[passIdx];
    updateActive(passIdx, { ...pass, updating: true });
    updateDoc(doc(db, path), { expires: newExpiry } as Partial<PassWithPerson>)
      .then(() => {
        updateActive(passIdx, { ...pass, updating: false, expires: newExpiry });
      })
      .catch((err) => {
        console.error(err);
        alert(err);
      });
  };

  const passHasExpiry = (passExpiry?: Date) => {
    return (
      passExpiry &&
      passExpiry.getFullYear() > 0 &&
      passExpiry.getFullYear() < TimestampMaxYear
    );
  };

  const renderPass = (pass: PassWithPerson) => {
    const passExpiry = pass.expires?.toDate();
    const hasExpiry = passHasExpiry(passExpiry);
    const expiryStr = hasExpiry ? passExpiry?.toLocaleDateString("en-AU") : "";
    const expiryClass = pass.updating
      ? "disabled"
      : passExpiry < now
      ? "alert"
      : passExpiry < warnFrom
      ? "warn"
      : "";

    const setExpiryDate = () => {
      const newExpiry = prompt(
        `New expiry date for pass\nPass type: ${pass.name}\nPerson: ${pass.person.name}`,
        expiryStr
      );
      if (!newExpiry || newExpiry === expiryStr) {
        // cancelled or unchanged
        return;
      }
      const newExpiryDate = parseAUDate(newExpiry);
      if (!newExpiryDate) {
        return;
      }
      newExpiryDate.setHours(passExpiry.getHours());
      newExpiryDate.setMinutes(passExpiry.getMinutes());
      newExpiryDate.setSeconds(passExpiry.getSeconds());
      updateExpiries(
        pass.collectionPath as string,
        Timestamp.fromDate(newExpiryDate)
      );
    };

    const addWeek = () => {
      passExpiry.setDate(passExpiry.getDate() + 7);
      updateExpiries(
        pass.collectionPath as string,
        Timestamp.fromDate(passExpiry)
      );
    };

    const clearExpiry = () => {
      if (
        confirm(
          "Clear expiry? Will reset to full length of time when next used"
        )
      ) {
        updateExpiries(pass.collectionPath as string, TimestampMax);
      }
    };

    return (
      <tr class={expiryClass}>
        <td>{pass.person.name}</td>
        <td>{pass.name}</td>
        <td>{expiryStr}</td>
        <td>{pass.remaining_uses}</td>
        <td>
          <button onClick={setExpiryDate}>Set Expiry</button>
          {hasExpiry && <button onClick={addWeek}>+1 Week</button>}
          {hasExpiry && <button onClick={clearExpiry}>Clear Expiry</button>}
        </td>
      </tr>
    );
  };

  const addWeekToAll = () => {
    if (confirm("Extend all shown passes by 1 week?")) {
      activePasses.forEach((pass) => {
        const passExpiry = pass.expires?.toDate();
        if (!passHasExpiry(passExpiry)) {
          return;
        }
        passExpiry.setDate(passExpiry.getDate() + 7);
        updateExpiries(
          pass.collectionPath as string,
          Timestamp.fromDate(passExpiry)
        );
      });
    }
  };

  return (
    <div class="app-body-wrap">
      <div class="app-body">
        <h2>Active Passes</h2>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Pass Type</th>
              <th>Expires/Term</th>
              <th>Remaining</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>{activePasses.map(renderPass)}</tbody>
        </table>
        <Button onClick={addWeekToAll}>Extend All 1 Week</Button>
      </div>
    </div>
  );
}
