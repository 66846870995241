import { useState, useRef, useEffect } from "react";
import Scanner from "./Scanner";
import style from "./style.scss";
import { Attendance, BarcodeScan, Pass, PassType, Person } from "../../types";
import {
  Timestamp,
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { DOOR_PREFIX, db } from "../../firebase";
import { Button } from "@mui/material";
import { getCollection, getPersonPasses } from "../../utils/firebase";
import { usePass, usePrebookedPass } from "../../utils/door";
import { CameraSelectForm, initScanner } from "./camera_select";

type SignInOptions = "SIGNED_IN" | (Pass | Attendance)[];

let resetTimeout: ReturnType<typeof setTimeout> | undefined = undefined;

interface ClassOrEvent {
  id: string;
  collection: "class-nights" | "events";
  name: string;
}

export default function BarcodeScanner() {
  const [scanning, setScanning] = useState(false);
  const [cameras, setCameras] = useState<MediaDeviceInfo[]>([]);
  const [cameraId, setCameraId] = useState<string>("");
  const [cameraError, setCameraError] = useState<string>();
  const [classOrEvent, setClassOrEvent] = useState<ClassOrEvent>();
  const [passTypes, setPassTypes] = useState<Record<string, PassType>>();
  const [person, setPerson] = useState<Person>();
  const [signInOptions, _setSignInOptions] = useState<SignInOptions>();
  const [resultMsg, setResultMsg] = useState<string>();
  const scannerRef = useRef<HTMLDivElement>(null);

  const BUTTON_TIMEOUT = 10; // seconds

  const setSignInOptions = (options: SignInOptions) => {
    startResetTimeout();
    _setSignInOptions(options);
  };

  const startResetTimeout = () => {
    if (resetTimeout) {
      clearTimeout(resetTimeout);
    }
    resetTimeout = setTimeout(() => {
      startScanning();
    }, BUTTON_TIMEOUT * 1000);
  };

  const setScanRecord = (passName?: string | undefined) => {
    const personId = person?.id as string;
    const scanRef = doc(
      db,
      classOrEvent?.collection as string,
      classOrEvent?.id as string,
      DOOR_PREFIX + "barcode-scans",
      personId
    );
    const scanData: BarcodeScan = {
      id: personId,
      name: person?.name,
      ...(passName ? { passName } : {}),
      time: Timestamp.now(),
      acknowledged: false,
    };
    setDoc(scanRef, scanData, { merge: true });
  };

  const getSignIn = () => {
    if (resetTimeout) {
      clearTimeout(resetTimeout);
      resetTimeout = undefined;
    }
    const collectionPath = `${classOrEvent?.collection}/${classOrEvent?.id}/${DOOR_PREFIX}attendance/`;
    getDocs(
      query(
        collection(db, collectionPath),
        where("person", "==", doc(db, person?.collectionPath as string))
      )
    ).then((snapshot) => {
      if (snapshot?.docs?.length === 1) {
        const doc = snapshot.docs[0].data() as Attendance;
        if (doc) {
          if (doc.is_present) {
            setSignInOptions("SIGNED_IN");
            return;
          } else {
            setSignInOptions([doc]);
            return;
          }
        }
      }
      const personId = person?.id as string;
      getPersonPasses(personId, true).then(setSignInOptions);
    });
  };

  useEffect(() => {
    getCollection("pass-types").then((passTypes) =>
      setPassTypes(
        Object.fromEntries(
          passTypes.map((passType) => [passType.collectionPath, passType])
        )
      )
    );
  }, []);

  const setResult = (barcode?: string) => {
    if (barcode?.match(/^CMJA\d{6}$/)) {
      setScanning(false);

      getDocs(
        query(
          collection(db, DOOR_PREFIX + "person"),
          where("barcode", "==", barcode)
        )
      ).then((snapshot) => {
        if (snapshot?.docs?.length === 1) {
          const doc = snapshot.docs[0];
          setPerson({
            id: doc.id,
            collectionPath: doc.ref.path,
            ...doc.data(),
          } as Person);
          startResetTimeout();
        } else {
          setPerson(undefined);
          setResultMsg("Error: Didn't find barcode " + barcode);
        }
      });
    }
  };

  const startScanning = () => {
    setScanning(true);
    setResultMsg(undefined);
    setPerson(undefined);
    _setSignInOptions(undefined);
  };

  useEffect(() => {
    const since = new Date();
    since.setDate(since.getDate() - 1);
    const until = new Date();
    until.setDate(until.getDate() + 1);

    const mkQuery = (table: string, field: string) =>
      query(
        collection(db, table),
        where(field, ">=", since),
        where(field, "<=", until)
      );
    const classQuery = mkQuery("class-nights", "date");
    const eventQueryStart = mkQuery("events", "start");
    const eventQueryEnd = mkQuery("events", "end");

    Promise.all(
      [classQuery, eventQueryStart, eventQueryEnd].map((q) => getDocs(q))
    ).then((snapshotSet) => {
      const classNights = snapshotSet[0].docs.map((doc) => doc.data());
      const danceEvents = Object.values(
        Object.fromEntries(
          snapshotSet[1].docs
            .concat(snapshotSet[2].docs)
            .map((doc) => [doc.id, doc.data()])
        )
      );
      const totalCount = classNights.length + danceEvents.length;
      if (totalCount < 1) {
        setResultMsg("Error: Didn't find today's class/event");
      } else if (totalCount > 1) {
        setResultMsg("Error: Found multiple classes/events");
      } else if (classNights.length > 0) {
        const name =
          "Class Night " +
          classNights[0].date.toDate().toLocaleDateString("en-AU");
        setClassOrEvent({
          name,
          id: classNights[0].id,
          collection: "class-nights",
        });
      } else {
        const name =
          danceEvents[0].name ||
          "Dance Event " +
            classNights[0].date.toDate().toLocaleDateString("en-AU");
        setClassOrEvent({ name, id: danceEvents[0].id, collection: "events" });
      }
    });

    const disableCamera = initScanner({
      setCameraError,
      setCameraId,
      setCameras,
      startScanning,
    });

    return () => {
      disableCamera();
      if (resetTimeout) {
        clearTimeout(resetTimeout);
      }
    };
  }, []);

  const signInForm = () => {
    if (!(signInOptions && person)) {
      return <></>;
    }
    if (signInOptions === "SIGNED_IN") {
      return <>Already signed in, happy dancing!</>;
    }
    setScanRecord();
    if (signInOptions?.length) {
      const onClick = (option: Pass | Attendance) => {
        let promise;
        const useProps = {
          personId: person.id,
          public: true,
          ...(classOrEvent?.collection === "class-nights"
            ? { classId: classOrEvent.id }
            : { eventId: classOrEvent?.id }),
          passTypes: passTypes as Record<string, PassType>,
        };
        if ("is_present" in option) {
          // it's an Attendance type
          promise = usePrebookedPass({
            ...useProps,
            attendance: option,
          });
        } else {
          promise = usePass({
            ...useProps,
            pass: option,
          });
        }
        promise
          .then(() => {
            setScanRecord(option.name);
            setResultMsg("Checked in, happy dancing!");
          })
          .catch((error) => {
            setResultMsg("Error: " + error);
          })
          .finally(() => startResetTimeout());
      };
      return (
        <>
          Sign in options:
          {signInOptions.map((option) => (
            <Button onClick={() => onClick(option)}>{option.name}</Button>
          ))}
        </>
      );
    }
    return <>No current passes, please see our door staff</>;
  };

  const scanButton = (key?: string) => (
    <Button
      key={key}
      onClick={startScanning}
      style={{ animationDuration: BUTTON_TIMEOUT + "s" }}
    >
      Scan again
    </Button>
  );

  const resultForm = () => {
    if (resultMsg) {
      return (
        <>
          {resultMsg}
          <br />
          {scanButton("Err")}
        </>
      );
    }
    if (signInOptions) {
      return (
        <>
          {signInForm()}
          {scanButton("signinOps")}
        </>
      );
    }
    if (person) {
      return (
        <>
          <Button
            style={{ animationDuration: BUTTON_TIMEOUT + "s" }}
            onClick={getSignIn}
          >
            {person.barcode}
            <br />
            {person.name}
          </Button>
          {scanButton("selectPerson")}
        </>
      );
    }
    return <></>;
  };

  if (!classOrEvent) {
    if (resultMsg) {
      return <div class={style.scannerWrapErr}>{resultMsg}</div>;
    }
    return <div class={style.scannerWrapErr}>Loading...</div>;
  }

  return (
    <>
      <div class={style.scannerWrapOuter}>
        <h2>{classOrEvent.name}</h2>
        <div ref={scannerRef} class={style.scannerWrap}>
          <canvas class="drawingBuffer" width="640" height="480" />
          {scanning ? (
            <Scanner
              scannerRef={scannerRef}
              cameraId={cameraId}
              onDetected={setResult}
            />
          ) : null}
        </div>
        <CameraSelectForm
          cameraError={cameraError}
          cameras={cameras}
          setCameraId={setCameraId}
        />
        {!person && (
          <div>
            <button onClick={() => setScanning(!scanning)}>
              {scanning ? "Stop" : "Start"}
            </button>
          </div>
        )}
        <div class={style.result}>{resultForm()}</div>
      </div>
    </>
  );
}
