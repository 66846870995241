import { useState, useEffect } from "preact/hooks";
import {
  getStorage,
  ref,
  list,
  getDownloadURL,
  StorageReference,
  getMetadata,
  FullMetadata,
  deleteObject,
} from "firebase/storage";

import { Alert, Button, CircularProgress, Snackbar } from "@mui/material";
import style from "./media/media.scss";
import { Icons } from "./media/icons";

import { uploadFile } from "../../../utils/firebase";

const humanFileSize = (size: number) => {
  const i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return (
    (size / Math.pow(1024, i)).toFixed(2) +
    " " +
    ["B", "kB", "MB", "GB", "TB"][i]
  );
};

const CopyableDisplay = (
  url: string,
  metadata: StorageMetadata,
  showUpload: (arg0: StorageReference) => void,
  showImages: boolean
) => {
  const [hidden, setHidden] = useState<boolean>(false);
  const [showImage, setShowImage] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");

  const contentTypeImage = metadata.meta.contentType?.startsWith("image/");
  const copyLinkToClipboard = () => {
    setSnackbarMessage("URL Copied to clipboard");
    navigator.clipboard.writeText(url);
  };

  const deleteItem = () => {
    const prompt =
      "Are you sure you wish to delete this file?\nThis can't be undone!";
    if (confirm(prompt)) {
      deleteObject(metadata.ref)
        .then(() => {
          setHidden(true); // we know it's gone, no need to trigger a new storage list call
          setSnackbarMessage("File deleted");
        })
        .catch((error) => {
          alert("Error deleting file!\n" + error + "\nWas it already deleted?");
        });
    }
  };

  const image = () => {
    if (contentTypeImage) {
      if (showImages || showImage) {
        return <img src={url} />;
      }
      return Icons.imagesmode;
    }
    if (metadata.meta.contentType?.startsWith("video/")) {
      return Icons.movie;
    }
    if (metadata.meta.contentType?.startsWith("audio/")) {
      return Icons.genres_audio;
    }
    if (metadata.meta.contentType === "application/pdf") {
      return Icons.picture_as_pdf;
    }
    return Icons.file;
  };
  if (hidden) {
    return <></>;
  }

  return (
    <>
      <Button onClick={copyLinkToClipboard}>{image()}</Button>
      {!showImages && contentTypeImage && (
        <Button onClick={() => setShowImage(!showImage)}>
          {showImage ? "Hide" : "Show"} Image
        </Button>
      )}
      <table>
        <tbody>
          <tr>
            <td>File name</td>
            <td>{metadata.meta.name}</td>
          </tr>
          <tr>
            <td>File size</td>
            <td>{humanFileSize(metadata.meta.size)}</td>
          </tr>
          <tr>
            <td>Uploaded</td>
            <td>{new Date(metadata.meta.updated).toLocaleString("en-AU")}</td>
          </tr>
        </tbody>
      </table>
      <Button onClick={copyLinkToClipboard}>Copy URL</Button>
      <Button onClick={deleteItem}>Delete</Button>
      <Button href={url} target="_new">
        Open in new tab
      </Button>
      {showUpload(metadata.ref)}
      <Snackbar
        open={snackbarMessage.length > 0}
        onClose={() => setSnackbarMessage("")}
        autoHideDuration={2000}
        message={snackbarMessage}
      />
    </>
  );
};

interface StorageMetadata {
  ref: StorageReference;
  meta: FullMetadata;
}
type StorageEntries = Record<string, StorageMetadata>;

export default function AdminMedia() {
  const [storageEntries, setStorageEntries] = useState<StorageEntries>({});
  const [showImages, setShowImages] = useState(false);
  const storage = getStorage();

  const update = () => {
    const listRef = ref(storage);

    list(listRef, { maxResults: 1000 }).then((items) => {
      Promise.all([
        ...items.items.map((item) =>
          getDownloadURL(ref(storage, item.fullPath))
        ),
        ...items.items.map((item) => getMetadata(ref(storage, item.fullPath))),
      ]).then((urlsAndMetadata) => {
        const half = urlsAndMetadata.length / 2;
        const urls = urlsAndMetadata.slice(0, half);
        const metadata = urlsAndMetadata.slice(half);
        setStorageEntries(
          Object.fromEntries(
            items.items.map((_, i) => [
              urls[i],
              { ref: ref(items.items[i]), meta: metadata[i] },
            ])
          )
        );
      });
    });

    // // Fetch the second page if there are more elements.
    // if (firstPage.nextPageToken) {
    //   const secondPage = await list(listRef, {
    //     maxResults: 100,
    //     pageToken: firstPage.nextPageToken,
    //   });
    //   // processItems(secondPage.items)
    //   // processPrefixes(secondPage.prefixes)
    // }
  };
  useEffect(() => {
    update();
  }, []);
  if (Object.keys(storageEntries).length <= 0) {
    return <div>No files found</div>;
  }

  const uploadButton = (storageRef?: StorageReference) => {
    const [uploadState, setuploadState] = useState<string>();
    const [uploadProgress, setUploadProgress] = useState<number | undefined>();
    const uploadCallback = (state: string, progress?: number) => {
      setuploadState(state);
      if (progress) {
        setUploadProgress(progress);
      }
    };
    const upload = (file: File) => uploadFile(file, storageRef, uploadCallback);

    const checkExistanceAndUpload = (fileName: string, file: File) => {
      storageRef = ref(storage, fileName);

      getMetadata(storageRef)
        .then((_) => {
          // already exists, enter new name
          const newFileName = prompt(
            "A file with this name already exists.\nPress enter to overwrite the existing file, cancel to cancel uploading, or enter a new file name",
            fileName
          );
          if (fileName === newFileName) {
            upload(file);
          } else if (newFileName) {
            return checkExistanceAndUpload(newFileName, file);
          }
          // else user cancelled
        })
        .catch((ex) => {
          if (ex.code === "storage/object-not-found") {
            upload(file);
          } else {
            throw ex;
          }
        });
    };

    const onChange = (e: Event) => {
      const files = (e.currentTarget as HTMLInputElement).files;
      if (!files || files.length < 1) {
        // cancelled, presumably?
        return;
      }

      if (storageRef) {
        upload(files[0]);
      } else {
        checkExistanceAndUpload(files[0].name, files[0]);
      }
    };

    const uploadProgressDisplay = () => {
      if (uploadState === "success") {
        return <Alert severity="success">Upload complete</Alert>;
      }
      if (uploadState === "error") {
        return <Alert severity="error">Upload failed</Alert>;
      }
      if (uploadState === "running") {
        return (
          <Alert severity="info">
            <CircularProgress variant="determinate" value={uploadProgress} />
          </Alert>
        );
      }
    };

    return (
      <>
        <label class={style.upload}>
          <Button>Upload {storageRef ? "Replacement" : "New"} File</Button>
          <input type="file" onChange={onChange} />
        </label>
        {uploadProgressDisplay()}
      </>
    );
  };

  return (
    <div class="app-body-wrap">
      <div class={"app-body " + style.media}>
        <h2>Files in Storage</h2>
        <p>
          To add a link to a file (e.g. a PDF):
          <ul>
            <li>Upload file (if not yet uploaded)</li>
            <li>Click "Copy URL"</li>
            <li>
              Go to page editor, write the text of the link, and select it
            </li>
            <li>Click the "Link" button in the editor's toolbar</li>
            <li>Paste the URL</li>
          </ul>
          You can also delete old files (ensure they're not in use anymore!) or
          replace a file with a new version
        </p>
        <p>{uploadButton()}</p>
        <p>
          <label>
            <input
              type="checkbox"
              checked={showImages}
              onClick={() => {
                setShowImages(!showImages);
              }}
            />
            Show All Images
          </label>
        </p>
        {Object.keys(storageEntries).map((url) => (
          <div class={style.item}>
            {CopyableDisplay(
              url,
              storageEntries[url],
              uploadButton,
              showImages
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
