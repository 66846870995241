import {
  ClassNight,
  Curriculum,
  FirebaseBackedObject,
  FirebaseValueType,
  PartialRecord,
} from "src/types";
import FormPage, {
  TableFormType,
  CollectionsRecord,
  IdNamePair,
} from "../../../../components/forms/table";
import { Timestamp, orderBy } from "firebase/firestore";

export const DEFAULT_START_TIME = {
  hour: 19,
  minute: 0,
} as const;

export const AdminClassesDefinition = () => {
  type TableType = ClassNight;

  const dbCollection = "class-nights";
  const displayOrderFields: (keyof TableType)[] = ["date"];
  const displayOrder = displayOrderFields.map((field) =>
    orderBy(field, "desc")
  );
  const limit = 10;

  type formLine = [label: string, field: keyof TableType];
  const formFields: formLine[] = [
    ["Date", "date"],
    ["Location", "location"],
    ["Teaching Beginner", "teachingBeginner"],
    ["Teaching Fundamentals", "teachingFundamentals"],
    ["Teaching Variations", "teachingVariations"],
    ["Curriculum", "curriculum"],
    ["Moves in Beginner", "taughtBeginner"],
    ["Moves in Fundamentals", "taughtFundamentals"],
    ["Moves in Variations", "taughtVariations"],
    ["Video of Variations", "variationsVideo"],
    ["Beginner Playlist", "beginnerPlaylist"],
    ["Intermediate Playlist", "intermediatePlaylist"],
  ];

  const formTypes: PartialRecord<keyof TableType, TableFormType> = {
    date: "datetime-local",
    location: "locations",
    curriculum: "curriculums",
  };

  const requiredFields = ["date", "location"];

  const nameFormatter = (
    result: FirebaseBackedObject,
    collections?: CollectionsRecord
  ) => {
    const classNight = result as TableType;
    const classLocation = classNight.location;
    const locationPath =
      "string" === typeof classLocation ? classLocation : classLocation.path;

    const location =
      locationPath &&
      collections &&
      collections["location"]?.find((location) => location[0] === locationPath);

    const date = classNight.date.toDate().toLocaleDateString("en-AU");
    if (location) {
      return date + " at " + location[1];
    }
    return date;
  };

  const pagingOffset = (lastDoc: FirebaseBackedObject) => {
    return displayOrderFields.map((field) => (lastDoc as TableType)[field]);
  };

  const extraButtons = {
    "Import from FB post": (
      _instance: FirebaseBackedObject | undefined,
      setValue: (field: string, value: string | Timestamp) => void
    ) => {
      const input = prompt("paste schedule from FB post");
      if (!input) {
        return;
      }
      const reDate =
        "Thursday\\s+(?<date>\\d+)/(?<month>\\d+)/(?<year>\\d+)\\b";
      const rePeople = (level: string) =>
        `^\\s*${level}:\\s*(?<${level.toLocaleLowerCase()}>[\\w\\s]+&[\\w\\s]+?)\\s*$`;
      const pattern = new RegExp(
        [
          reDate,
          ...["Beginner", "Fundamentals", "Variations"].map((level) =>
            rePeople(level)
          ),
        ].join(".*"),
        "msi"
      );
      const matches = input.match(pattern);
      if (!matches?.groups) {
        alert("Didn't match");
        return;
      }

      setValue(
        "date",
        Timestamp.fromDate(
          new Date(
            matches.groups.year.padStart(4, "20") +
              "-" +
              matches.groups.month +
              "-" +
              matches.groups.date +
              "T" +
              DEFAULT_START_TIME.hour.toString().padStart(2, "0") +
              ":" +
              DEFAULT_START_TIME.minute.toString().padStart(2, "0")
          )
        )
      );

      setValue("teachingBeginner", matches.groups.beginner);
      setValue("teachingFundamentals", matches.groups.fundamentals);
      setValue("teachingVariations", matches.groups.variations);
    },
    "Apply Curriculum": (
      instance: FirebaseBackedObject | undefined,
      setValue: (field: string, value: string | Timestamp) => void,
      collections?: Record<string, IdNamePair[]>
    ) => {
      const classNight = instance as ClassNight | undefined;
      const curriculumPath =
        typeof classNight?.curriculum === "string"
          ? classNight?.curriculum
          : classNight?.curriculum?.path;

      if (
        !(
          classNight?.curriculum &&
          collections?.curriculum &&
          typeof classNight.curriculumOffset !== "undefined"
        )
      ) {
        return;
      }

      const curriculum = collections.curriculum.find(
        (night) => night[0] === curriculumPath
      )?.[1] as Curriculum;
      if (!curriculum?.nights) {
        return;
      }

      const curriculumNight = curriculum.nights[classNight.curriculumOffset];
      if (curriculumNight.beginner && !classNight.taughtBeginner) {
        setValue("taughtBeginner", curriculumNight.beginner);
      }
      if (curriculumNight.fundamentals && !classNight.taughtFundamentals) {
        setValue("taughtFundamentals", curriculumNight.fundamentals);
      }
      if (curriculumNight.variations && !classNight.taughtVariations) {
        setValue("taughtVariations", curriculumNight.variations);
      }
    },
  };

  const d = new Date();
  const day = d.getDay();
  d.setHours(DEFAULT_START_TIME.hour);
  d.setMinutes(DEFAULT_START_TIME.minute);
  d.setSeconds(0);
  d.setMilliseconds(0);

  const formDefaults: PartialRecord<keyof TableType, FirebaseValueType> = {
    date: Timestamp.fromDate(
      // Target Thursday (4), plus 7 to keep %7 positive
      new Date(d.setDate(d.getDate() + ((4 + 7 - day) % 7)))
    ),
  };

  return {
    dbCollection,
    formFields,
    formTypes,
    requiredFields,
    displayOrder,
    limit,
    pagingOffset,
    canDelete: true,
    nameFormatter,
    extraButtons,
    formDefaults,
  };
};

export default function AdminClasses() {
  return FormPage(AdminClassesDefinition());
}
