import { Button, Input } from "@mui/material";
import { useEffect, useState } from "preact/hooks";

import style from "../../../../components/forms/style.scss";
import {
  dateFormat,
  dateFormatAU,
  endPreviousCalendarYear,
  endPreviousFinancialYear,
  startCalendarYear,
  startFinancialYear,
  startPreviousCalendarYear,
  startPreviousFinancialYear,
} from "../../../../utils/datetime";
import { ClassNight, Location } from "../../../../types";
import {
  collection,
  doc,
  getCountFromServer,
  getDocs,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import { db } from "../../../../firebase";
import { AdminClassesDefinition } from "../classes/classes";
import { getCollection } from "../../../../utils/firebase";

const today = new Date();

interface ClassWithSummary extends ClassNight {
  attendances?: number;
}

export default function AdminReportClasses() {
  const classDefinition = AdminClassesDefinition();

  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string | undefined>(dateFormat(today));

  const [classes, setClasses] = useState<ClassWithSummary[]>();
  // const [danceEvents, setDanceEvents] = useState<DanceEvent[]>();
  const [locations, setLocations] = useState<Record<string, Location>>();

  const getClasses = () => {
    if (!(startDate && endDate)) {
      return;
    }
    getDocs(
      query(
        collection(db, classDefinition["dbCollection"]),
        ...classDefinition["displayOrder"],
        where("date", ">=", Timestamp.fromDate(new Date(startDate))),
        where("date", "<=", Timestamp.fromDate(new Date(endDate)))
      )
    ).then((querySnapshot) => {
      const paths = querySnapshot.docs.map((snapDoc) => snapDoc.ref.path);
      Promise.all(
        paths.map((path) =>
          getCountFromServer(collection(db, path, "attendance"))
        )
      ).then((attendances) => {
        setClasses(
          querySnapshot.docs.map((snapDoc, i) => {
            const night = snapDoc.data();
            if (typeof night.location === "string") {
              night.location = doc(db, night.location as unknown as string);
            }

            return {
              ...night,
              attendances: attendances[i].data().count,
            } as ClassWithSummary;
          })
        );
      });
    });
  };

  useEffect(() => {
    getCollection("locations", true).then((resultList) =>
      setLocations(
        Object.fromEntries(
          resultList.map((result) => [result.collectionPath, result])
        )
      )
    );
  }, []);
  if (!locations) {
    return <></>;
  }

  const currCalBtn = () => {
    setStartDate(dateFormat(startCalendarYear));
    setEndDate(dateFormat(today));
  };
  const prevCalBtn = () => {
    setStartDate(dateFormat(startPreviousCalendarYear));
    setEndDate(dateFormat(endPreviousCalendarYear));
  };
  const currFinBtn = () => {
    setStartDate(dateFormat(startFinancialYear));
    setEndDate(dateFormat(today));
  };
  const prevFinBtn = () => {
    setStartDate(dateFormat(startPreviousFinancialYear));
    setEndDate(dateFormat(endPreviousFinancialYear));
  };

  return (
    <div class="app-body-wrap">
      <div class="app-body">
        <h2>Class Summary</h2>
        <table class={style.formTable}>
          <tbody>
            <tr>
              <th>From:</th>
              <td>
                <Input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.currentTarget.value)}
                />
              </td>
            </tr>
            <tr>
              <th>To:</th>
              <td>
                <Input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.currentTarget.value)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <Button onClick={currCalBtn}>Current Calendar Year</Button>
                <Button onClick={prevCalBtn}>Previous Calendar Year</Button>
                <Button onClick={currFinBtn}>Current Financial Year</Button>
                <Button onClick={prevFinBtn}>Previous Financial Year</Button>
              </td>
            </tr>
            <tr>
              <td>
                <Button disabled={!(startDate && endDate)} onClick={getClasses}>
                  Generate Report
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
        {classes?.length && (
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Location</th>
                <th>Attendees</th>
              </tr>
            </thead>
            <tbody>
              {classes?.map((classNight) => (
                <tr>
                  <td>{dateFormatAU(classNight.date)}</td>
                  <td>
                    {classNight.location?.path &&
                      locations[classNight.location.path]?.name}
                  </td>
                  <td>{classNight.attendances}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th>Total: {classes?.length}</th>
                <th></th>
                <th>
                  {classes
                    ?.map((classNight) => classNight.attendances || 0)
                    .reduce((partialSum, a) => partialSum + a, 0)}
                </th>
              </tr>
            </tfoot>
          </table>
        )}
      </div>
    </div>
  );
}
