import { PaymentMethods, Sale, SaleLine } from "../../../types";
import { getCollection } from "../../../utils/firebase";

export const getItemNames = (): Promise<Record<string, string>> => {
  return new Promise((resolve) => {
    const collectionsQueries = ["stock", "pass-types"].map((typeName) => {
      return getCollection(typeName);
    });

    Promise.all(collectionsQueries).then((resultListList) => {
      resolve(
        Object.fromEntries(
          resultListList
            .filter((resultList) => resultList.length > 0)
            .flatMap((resultList) => [
              resultList.map((result) => [result.collectionPath, result.name]),
            ])
            .flat()
        )
      );
    });
  });
};

export const saleItemNamesString = (
  sale: Sale,
  itemNames: Record<string, string>
) => {
  return sale?.lines?.map((line: SaleLine) => (
    <>
      {line.quantity !== 1 && line.quantity + "* "}
      {line.name || itemNames[line.item.path]}
    </>
  ));
};

export function PersonSalesPanel(
  sales: Sale[],
  itemNames: Record<string, string>
) {
  return (
    <table>
      <thead>
        <tr>
          <th>Date</th>
          <th>Amount</th>
          <th>Method</th>
          <th>Items</th>
          <th>Notes</th>
        </tr>
      </thead>
      <tbody>
        {sales.map((sale) => (
          <tr class={sale.refunded ? "disabled" : undefined}>
            <td>{sale.time?.toDate().toLocaleDateString("en-AU")}</td>
            <td>${sale.amount}</td>
            <td>
              {PaymentMethods[sale.method as keyof typeof PaymentMethods]}
            </td>
            <td>{saleItemNamesString(sale, itemNames)}</td>
            <td>
              {sale.notes} {sale.refunded && " Refunded"}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
