import { useEffect, useState } from "preact/compat";
import {
  DB_TABLE_NAME,
  Extract,
  Import,
  ImportTimes,
} from "../../../utils/import";
import {
  DocumentReference,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../../firebase";
import { ClassNight } from "../../../types";
import { dateFormatAsLocal } from "../../../utils/datetime";
import { minTimestamp } from "../../../utils/utils";

type CompressionFormat = "deflate" | "deflate-raw" | "gzip";
type DecompressionStream = GenericTransformStream;
declare const DecompressionStream: {
  prototype: DecompressionStream;
  new (format: CompressionFormat): DecompressionStream;
};

// Event should come before Sale, Attendance, Entry
const tables: DB_TABLE_NAME[] = [
  "Terms",
  "Pass",
  "Stock",
  "Person",
  "Sale",
  "Discount",
  "Entry",
  "Attendance",
];

const tableToLastImport: Partial<Record<DB_TABLE_NAME, string>> = {
  Person: "person",
  Sale: "sale",
  Entry: "entry",
  // Event: "event",
  Attendance: "attendance",
};

export default function AdminImport() {
  const [fileContents, setFileContents] = useState<string>("");
  const [lastImports, setLastImports] = useState<ImportTimes>();
  const [recentEvents, setRecentEvents] =
    useState<Record<string, DocumentReference>>();
  const [importResults, setImportResults] =
    useState<Partial<Record<DB_TABLE_NAME, string | undefined>>>();

  const getImportTimes = () => {
    getDoc(doc(db, "import/times")).then((doc) => {
      const data = doc.data();
      setLastImports(data);
      // TODO: not how 'recent event' should be defined
      if (data && ("attendance" in data || "sale" in data)) {
        getDocs(
          query(
            collection(db, "class-nights"),
            where("date", ">=", minTimestamp(data["attendance"], data["sale"]))
          )
        ).then((querySnapshot) => {
          setRecentEvents(
            Object.fromEntries(
              querySnapshot.docs.map((doc) => [
                dateFormatAsLocal((doc.data() as ClassNight).date),
                doc.ref,
              ])
            )
          );
        });
      }
    });
  };

  useEffect(getImportTimes, []);

  const handleFiles = (files: FileList) => {
    if (!(files && files[0])) {
      console.error("handleFiles didn't get a file");
      return;
    }
    setImportResults({});
    new Response(files[0].stream().pipeThrough(new DecompressionStream("gzip")))
      .text()
      .then((contents) =>
        setFileContents(
          contents.replace(/\);\nINSERT INTO `\w+` VALUES/, "), ")
        )
      );
  };

  const onDragOver = (e: DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
    // Style the drag-and-drop as a "copy file" operation.
    if (e.dataTransfer) {
      e.dataTransfer.dropEffect = "copy";
    }
  };

  const onDrop = (e: DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
    e.dataTransfer?.files && handleFiles(e.dataTransfer?.files);
  };

  const setImportResult = (table: DB_TABLE_NAME, result: string) =>
    setImportResults({
      ...importResults,
      [table]: result,
    });

  const _extractFile = (table: DB_TABLE_NAME, fileContents: string) => {
    if (!lastImports) {
      return;
    }
    const lastImportName = tableToLastImport[table];
    const lastImport =
      lastImportName &&
      lastImports[lastImportName]?.toDate().toLocaleDateString("en-AU");
    const tuples = Extract(table, fileContents, lastImports, {
      ...(recentEvents && { events: recentEvents }),
    });
    if (tuples.length > 50) {
      console.log(
        table,
        "Got",
        tuples.length,
        "results",
        tuples.slice(0, 20),
        "...",
        tuples.slice(tuples.length - 20)
      );
    } else {
      console.log(table, { tuples });
    }

    if (lastImport) {
      return `Read ${tuples.length} new/updated entries since ${lastImport}`;
    }
    return `Read ${tuples.length} entries`;
  };

  const extractFile = (table: DB_TABLE_NAME, fileContents: string) => {
    if (!lastImports) {
      return;
    }
    setImportResult(table, _extractFile(table, fileContents) || "");
  };

  const extractAllFiles = (fileContents: string, all = false) => {
    if (!lastImports) {
      return;
    }
    const filteredTables = all
      ? tables
      : tables.filter((table) => {
          const lastImportName = tableToLastImport[table];
          return lastImports && lastImportName && lastImports[lastImportName];
        });

    setImportResults(
      Object.fromEntries(
        filteredTables.map((table) => [[table], "Reading file..."])
      )
    );
    setTimeout(
      () =>
        setImportResults(
          Object.fromEntries(
            filteredTables.map((table) => [
              [table],
              _extractFile(table, fileContents) || "",
            ])
          )
        ),
      1
    );
  };

  const importFile = (
    table: DB_TABLE_NAME,
    fileContents: string,
    remainingTables?: DB_TABLE_NAME[],
    bulkImportResults: Partial<Record<DB_TABLE_NAME, string | undefined>> = {}
  ) => {
    if (!lastImports) {
      return;
    }

    const setResult = (msg: string) => {
      bulkImportResults[table] = msg;
      setImportResults({
        ...importResults,
        ...bulkImportResults,
      });
    };

    setResult("Reading file...");

    Import(table, fileContents, lastImports, {
      ...(recentEvents && { events: recentEvents }),
    })
      .then((results) => {
        setResult(`Imported ${results} entries`);
        if (remainingTables?.length) {
          importFile(
            remainingTables[0],
            fileContents,
            remainingTables.slice(1),
            bulkImportResults
          );
        } else {
          getImportTimes();
        }
      })
      .catch((err) => setResult("Error importing: " + err));
  };

  const buttons = () => {
    if (!fileContents) {
      return;
    }
    const importAll = () => {
      importFile(tables[0], fileContents, tables.slice(1));
    };
    const importRecent = () => {
      const recentTables = tables.filter(
        (table) =>
          lastImports &&
          tableToLastImport[table] &&
          lastImports[tableToLastImport[table] as string]
      );
      importFile(recentTables[0], fileContents, recentTables.slice(1));
    };
    return (
      <div>
        <br />
        <br />
        {lastImports && (
          <>
            <div>
              All:&nbsp;
              <button onClick={() => extractAllFiles(fileContents, true)}>
                Read
              </button>
              &nbsp;
              <button onClick={importAll}>Import</button>
            </div>
            <br />
            <div>
              Regularly Changing:&nbsp;
              <button onClick={() => extractAllFiles(fileContents)}>
                Read
              </button>
              &nbsp;
              <button onClick={importRecent}>Import</button>
            </div>
            <br />
            {tables.map((table) => (
              <div>
                {table}:&nbsp;
                <button onClick={() => extractFile(table, fileContents)}>
                  Read
                </button>
                &nbsp;
                <button onClick={() => importFile(table, fileContents)}>
                  Import
                </button>
                <div>
                  {importResults && importResults[table]}
                  &nbsp;
                </div>
                <br />
              </div>
            ))}
          </>
        )}
        <div>
          Only load in data that's needed - it may take some time to complete.
          <br />
          Ensure you use the latest database - if there's any data on the door
          computer that's been updated since the dump, it will be lost!
        </div>
      </div>
    );
  };

  return (
    <div class="app-body-wrap">
      <div class="app-body">
        <h2>Import data from current door database</h2>
        <label>
          <input
            type="file"
            id="db-file"
            accept={".csv.gz"}
            onChange={(e) =>
              e.currentTarget?.files && handleFiles(e.currentTarget?.files)
            }
            onDrop={onDrop}
            onDragOver={onDragOver}
          />
          <br />
          Select the database backup file
        </label>
        {buttons()}

        {lastImports && (
          <>
            <br />
            <br />
            Last Imports:
            <ul>
              {Object.keys(lastImports)
                .sort(
                  (a, b) =>
                    lastImports[b].toMillis() - lastImports[a].toMillis()
                )
                .map((table) => (
                  <li>
                    {table}:{" "}
                    {lastImports[table].toDate().toLocaleString("en-AU")}
                  </li>
                ))}
            </ul>
          </>
        )}
      </div>
    </div>
  );
}
