import {
  addDoc,
  collection,
  doc,
  DocumentReference,
  getDoc,
  getDocs,
  limit,
  query,
  setDoc,
  Timestamp,
} from "firebase/firestore";
import { ClassNight, Curriculum, Location, Notes } from "../../../../types";
import { AdminClassesDefinition } from "./classes";
import { useEffect, useState } from "preact/hooks";
import { db } from "../../../../firebase";
import {
  Button,
  Input,
  MenuItem,
  Select,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import { JSXInternal } from "preact/src/jsx";
import { dateTimeLocalFormat } from "../../../../utils/datetime";
import { getCollection } from "../../../../utils/firebase";
import { CurriculumSelect } from "../../../../components/forms/table";

interface RosterRoles {
  beginner: string[];
  variations: string[];
  teacher: string[];
  demo: string[];
}

interface ClassPair {
  teacher: string;
  demo?: string;
}

interface CurriculumSlice {
  curriculum?: string;
  offset?: number;
}

const classPairToString = (pair: ClassPair) => {
  if (pair.demo) {
    return pair.teacher + " & " + pair.demo;
  }
  return pair.teacher;
};

export default function AdminClassRoster() {
  const classDefinition = AdminClassesDefinition();
  const classesToShow = 24;

  const [classes, setClasses] = useState<ClassNight[]>();
  const [notes, setNotes] = useState<string>();
  const [highlighted, setHighlighted] = useState<string>();
  const [staff, setStaff] = useState<RosterRoles>();

  const [locations, setLocations] = useState<Record<string, Location>>();
  const [curriculums, setCurriculums] = useState<Curriculum[]>();

  const [teachingDate, setTeachingDate] = useState<Timestamp>(
    (classDefinition.formDefaults.date as Timestamp) || new Timestamp(0, 0)
  );
  const [teachingLocation, setTeachingLocation] = useState<string>();

  const [teachingBeginner, setTeachingBeginner] = useState<ClassPair>({
    teacher: "",
  });
  const [teachingFundamentals, setTeachingFundamentals] = useState<ClassPair>({
    teacher: "",
  });
  const [teachingVariations, setTeachingVariations] = useState<ClassPair>({
    teacher: "",
  });

  const [teachingCurriculum, setTeachingCurriculum] = useState<CurriculumSlice>(
    {}
  );

  const getClasses = () => {
    getDocs(
      query(
        collection(db, classDefinition["dbCollection"]),
        ...classDefinition["displayOrder"],
        limit(classesToShow)
      )
    ).then((querySnapshot) => {
      const classNights = querySnapshot.docs.map((snapDoc) => {
        const night = snapDoc.data();
        if (typeof night.location === "string") {
          return {
            ...night,
            location: doc(db, night.location as unknown as string),
          } as ClassNight;
        }
        return night as ClassNight;
      });
      setClasses(classNights);
      const beginner = Array.from(
        new Set(
          classNights.map((night) => night.teachingBeginner.split(/\s*&\s*/)[0])
        )
      ).sort();
      const variations = Array.from(
        new Set(
          classNights.map(
            (night) => night.teachingVariations.split(/\s*&\s*/)[0]
          )
        )
      ).sort();
      const teacher = Array.from(
        new Set(
          classNights.flatMap((night) => [
            night.teachingBeginner.split(/\s*&\s*/)[0],
            night.teachingFundamentals.split(/\s*&\s*/)[0],
            night.teachingVariations.split(/\s*&\s*/)[0],
          ])
        )
      ).sort();
      const demo = Array.from(
        new Set(
          classNights.flatMap((night) => [
            ...night.teachingBeginner.split(/\s*&\s*/),
            ...night.teachingFundamentals.split(/\s*&\s*/),
            ...night.teachingVariations.split(/\s*&\s*/),
          ])
        )
      ).sort();
      setStaff({ beginner, variations, teacher, demo });
    });
  };

  useEffect(() => {
    getClasses();
    getCollection("locations", true).then((resultList) =>
      setLocations(
        Object.fromEntries(
          resultList.map((result) => [result.collectionPath, result])
        )
      )
    );
    getCollection("curriculums").then(setCurriculums);
    getDoc(doc(db, "/notes/roster")).then((querySnapshot) => {
      setNotes((querySnapshot.data() as Notes).text);
    });
  }, []);

  const saveNotes = () => {
    setDoc(doc(db, "/notes/roster"), { text: notes });
  };

  if (!(classes && staff && locations && curriculums)) {
    return <>Loading...</>;
  }

  const notesForm = () => {
    return (
      <p>
        <TextareaAutosize
          minRows={1}
          value={notes}
          onChange={(
            e: JSXInternal.TargetedEvent<
              HTMLTextAreaElement | HTMLInputElement,
              Event
            >
          ) => setNotes(e.currentTarget.value)}
          style={{ width: "100%" }}
        />
        <Button onClick={() => saveNotes()}>Save notes</Button>
      </p>
    );
  };

  const classForm = () => {
    const dataList = (id: string, options: string[]) => {
      return (
        <datalist id={id}>
          {options.map((option) => (
            <option value={option} />
          ))}
        </datalist>
      );
    };

    const inputPair = (
      listName: string,
      current: ClassPair,
      setter: (value: ClassPair | ((prevState: ClassPair) => ClassPair)) => void
    ) => {
      return (
        <div class="row" style={{ alignItems: "baseline", gap: "0.5em" }}>
          <Input
            inputProps={{
              list: listName,
            }}
            value={current?.teacher}
            onChange={(e) =>
              setter((prev) => {
                return { ...prev, teacher: e.currentTarget.value };
              })
            }
          />
          <span>&amp;</span>
          <Input
            inputProps={{
              list: "demo",
            }}
            value={current?.demo}
            onChange={(e) =>
              setter((prev) => {
                return { ...prev, demo: e.currentTarget.value };
              })
            }
          />
        </div>
      );
    };

    // if we have multiple nights a week, may need to check for the prev class on the same day-of-week
    const prev = classes[0];
    const defaultLocation = prev.location.path || Object.keys(locations)[0];
    const defaultCurriculum = prev.curriculum?.path || "";
    const defaultCurriculumOffset = (prev.curriculumOffset || -1) + 1;

    const curriculumSelect = () => {
      const setter = (curriculum: string, offset: number) => {
        setTeachingCurriculum((was) => ({ ...was, curriculum, offset }));
      };

      const selectProps = {
        curriculums,
        setter,
        value: teachingCurriculum.curriculum || defaultCurriculum,
        key: "curriculum",
        offset: teachingCurriculum.offset || defaultCurriculumOffset,
        label: "Curriculum",
      };

      return <CurriculumSelect {...selectProps} />;
    };

    const inputRows = [
      {
        Date: (
          <Input
            type="datetime-local"
            value={dateTimeLocalFormat(teachingDate)}
            onChange={(e) =>
              setTeachingDate(
                Timestamp.fromDate(new Date(e.currentTarget.value))
              )
            }
          />
        ),
        Location: (
          <Select
            value={teachingLocation || defaultLocation}
            onChange={(e) => {
              const target = e.target as EventTarget & {
                value: string;
                name: string;
              };
              setTeachingLocation(target.value);
            }}
            label={"Location"}
          >
            {Object.entries(locations).map(([path, location]) => (
              <MenuItem value={path} name={location.name}>
                {location.name}
              </MenuItem>
            ))}
          </Select>
        ),
      },
      { Curriculum: curriculumSelect() },
      {
        Beginner: inputPair(
          "teachingBeginner",
          teachingBeginner,
          setTeachingBeginner
        ),
        Fundamentals: inputPair(
          "teaching",
          teachingFundamentals,
          setTeachingFundamentals
        ),
        Variations: inputPair(
          "teachingVariations",
          teachingVariations,
          setTeachingVariations
        ),
      },
    ];

    const createNight = () => {
      const classNight: ClassNight = {
        teachingBeginner: classPairToString(teachingBeginner),
        teachingFundamentals: classPairToString(teachingFundamentals),
        teachingVariations: classPairToString(teachingVariations),
        date: teachingDate,
        location: doc(
          db,
          teachingLocation || Object.keys(locations)[0]
        ) as DocumentReference<Location>,
      };
      if (teachingCurriculum.curriculum) {
        classNight.curriculum = doc(db, teachingCurriculum.curriculum);
        classNight.curriculumOffset = teachingCurriculum.offset;
      }
      addDoc(collection(db, "class-nights"), classNight)
        .then(() => getClasses())
        .catch(alert);
    };
    const showCurriculum = () => {
      const selectedCurriculum = curriculums.find(
        (curriculum) =>
          curriculum.collectionPath === teachingCurriculum.curriculum ||
          defaultCurriculum
      );
      const offset = teachingCurriculum?.offset || defaultCurriculumOffset;
      if (!selectedCurriculum?.nights || offset === undefined) {
        return;
      }
      const curriculumNight = selectedCurriculum.nights[offset];
      return (
        <div>
          <div>Beginner: {curriculumNight.beginner}</div>
          <div>Fundamentals: {curriculumNight.fundamentals}</div>
          <div>Variations: {curriculumNight.variations}</div>
        </div>
      );
    };
    return (
      <p>
        {dataList("teachingBeginner", staff.beginner)}
        {dataList("teachingVariations", staff.variations)}
        {dataList("teaching", staff.teacher)}
        {dataList("demo", staff.demo)}
        {inputRows.map((row) => (
          <div class="row" style={{ gap: "2em", marginBottom: "1em" }}>
            {Object.entries(row).map(([name, element]) => (
              <div class="f-grow">
                <strong>{name}</strong>
                <br />
                {element}
              </div>
            ))}
          </div>
        ))}
        {showCurriculum()}
        <Button onClick={createNight}>Create!</Button>
      </p>
    );
  };

  const pastClasses = () => {
    const teacherEntry = (teaching: string) => {
      const toSplitNames = () => {
        const asName = (name: string) => {
          return (
            <span
              onClick={() => setHighlighted(name)}
              className={name === highlighted ? "highlight" : ""}
            >
              {name}
            </span>
          );
        };
        const [teacher, demo] = teaching.split(/\s*&\s*/, 2);
        if (demo) {
          return (
            <>
              {asName(teacher)} &amp; {asName(demo)}
            </>
          );
        }
        return asName(teacher);
      };
      return <td>{toSplitNames()}</td>;
    };
    const renderClassNight = (classNight: ClassNight) => {
      const selectedCurriculum = curriculums.find(
        (curriculum) =>
          curriculum.collectionPath === classNight.curriculum?.path
      );
      const curriculumNight =
        selectedCurriculum?.nights && classNight.curriculumOffset !== undefined
          ? selectedCurriculum.nights[classNight.curriculumOffset]
          : undefined;
      return (
        <tr>
          <td>{classNight.date.toDate().toLocaleDateString("en-AU")}</td>
          <td>{locations[classNight.location.path].name}</td>
          <td>
            <Tooltip
              enterTouchDelay={100}
              leaveTouchDelay={5000}
              title={
                <div>
                  Beginner:{" "}
                  {classNight.taughtBeginner || curriculumNight?.beginner}
                  <br />
                  Variations:{" "}
                  {classNight.taughtVariations || curriculumNight?.variations}
                </div>
              }
            >
              <span>
                {classNight.taughtFundamentals || curriculumNight?.fundamentals}
              </span>
            </Tooltip>
          </td>
          {teacherEntry(classNight.teachingBeginner)}
          {teacherEntry(classNight.teachingFundamentals)}
          {teacherEntry(classNight.teachingVariations)}
        </tr>
      );
    };
    return (
      <p>
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Location</th>
              <th>Topic</th>
              <th>Beginner</th>
              <th>Fundamentals</th>
              <th>Variations</th>
            </tr>
          </thead>
          <tbody>{classes.map(renderClassNight)}</tbody>
        </table>
      </p>
    );
  };

  const copyableRoster = () => {
    const now = new Date();
    const until = new Date();
    until.setDate(until.getDate() + 7);

    const dateOptions = {
      weekday: "long",
      year: "numeric",
      month: "numeric",
      day: "numeric",
    } as const;

    const duringNextWeek = (classNight: ClassNight) => {
      const asDate = classNight.date.toDate();
      return asDate > now && asDate < until;
    };
    const classText = (classNight: ClassNight) => {
      const selectedCurriculum = curriculums.find(
        (curriculum) =>
          curriculum.collectionPath === classNight.curriculum?.path
      );
      const curriculumForNight = () => {
        if (
          !selectedCurriculum?.nights ||
          classNight.curriculumOffset === undefined
        ) {
          return { beginner: "", fundamentals: "", variations: "" };
        }
        return selectedCurriculum.nights[classNight.curriculumOffset];
      };
      const curriculumNight = curriculumForNight();

      return `${classNight.date
        .toDate()
        .toLocaleDateString("en-AU", dateOptions)}... ${
        curriculumNight.fundamentals
      }

Teachers: Think about your goal for the lesson and what tools you're going to use to achieve your goal. Look at the students there on the night and adapt your lesson plan. Talk to your partner about the goal and the plan and how you'd like to teach some specific points.

Beginner: ${curriculumNight.beginner}
New Plan for Variation, remember start with the difficult move 😉 & have a variation ready if needed for one of the moves. Don't feel you have to do all the moves, include theme practice and more music less talking! (for every class!)
Variations: ${curriculumNight.variations}

Set up: Everyone
Door:
Beginner: ${classNight.teachingBeginner}
Fundamentals: ${classNight.teachingFundamentals}
Variations: ${classNight.teachingVariations}

Have fun, help everyone else to have fun 😉
Cheers`;
    };

    const upcomingClasses = classes.filter(duringNextWeek);
    return (
      <div>
        Copyable post for FB (remember to add door and tag people)
        {upcomingClasses.map((classNight) => (
          <TextareaAutosize
            style={{ width: "100%" }}
            value={classText(classNight)}
          />
        ))}
        You can update people or curriculum at <a href="./classes">Classes</a>,
        then return for an updated post
      </div>
    );
  };

  return (
    <div class="app-body-wrap">
      <div class="app-body">
        <h2>Class Roster</h2>
        {notesForm()}
        {classForm()}
        {pastClasses()}
        {copyableRoster()}
      </div>
    </div>
  );
}
