import FacebookIcon from "@mui/icons-material/Facebook";
import { Link } from "preact-router";
import { loggedInUser, menuItemSet } from "src/types";
import { Auth } from "../auth";

import style from "./style.scss";

interface Props {
  links: menuItemSet;
  currentUser?: loggedInUser;
}

export const Footer = ({ links, currentUser }: Props) => {
  return (
    <footer class={style.footer}>
      <div class="footer">
        <div class="twocolumn">
          <h2>Contact Us</h2>
          <p>
            <b>Want to find out more?</b>
            <br />
            Give us a call on <a href="tel:+61413312583">
              0413 312 583
            </a> or{" "}
            <a target="" href="mailto:info@ceroc-adelaide.com.au">
              send us an email!
            </a>
          </p>
          <p>
            <b>Want to start dancing now?</b>
            <br />
            Just come to one of our classes.
            <br /> There's no need to book, and there are plenty of people
            waiting to dance with you!
          </p>
          <div>
            <div style={{ display: "flex" }}>
              <span>
                <a
                  href="https://www.facebook.com/ceroc/"
                  target="_blank"
                  title="Ceroc &amp; Modern Jive Adelaide"
                >
                  <FacebookIcon
                    style={{
                      color: "#0066FF",
                      fontSize: "3em",
                      margin: "-0.1em 0.1em 0 -0.15em",
                    }}
                  />
                </a>
              </span>
              <span>
                Like the{" "}
                <a href="https://www.facebook.com/ceroc/" target="_blank">
                  <b>Ceroc &amp; Modern Jive Adelaide</b> page on Facebook
                </a>{" "}
                and{" "}
                <a
                  href="https://www.instagram.com/ceroc_modernjive_adelaide/"
                  target="_blank"
                >
                  follow us on Instagram
                </a>{" "}
                to keep in touch with the latest news and events!
              </span>
            </div>
          </div>
        </div>
        <div class="twocolumn" id="newsletter">
          <h2>Newsletter</h2>

          <div>
            <b>
              Sign up to get the latest information about what's happening at
              Ceroc &amp; Modern Jive Adelaide each week!
            </b>
          </div>

          {/* <!-- Begin MailChimp Signup Form --> */}
          <div id="mc_embed_signup">
            <form
              action="//ceroc-adelaide.us14.list-manage.com/subscribe/post?u=1e91ff7a96f59f0bcf06cab7f&amp;id=75b4ecba83"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              class="validate"
              target="_blank"
              noValidate
            >
              <div id="mc_embed_signup_scroll">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <label for="mce-EMAIL">Email Address</label>
                      </td>
                      <td>
                        <input
                          type="email"
                          value=""
                          name="EMAIL"
                          class="required email"
                          id="mce-EMAIL"
                          placeholder="Email Address"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label for="mce-FNAME">First Name</label>
                      </td>
                      <td>
                        <input
                          type="text"
                          value=""
                          name="FNAME"
                          class=""
                          id="mce-FNAME"
                          placeholder="First Name"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label for="mce-LNAME">Last Name</label>
                      </td>
                      <td>
                        <input
                          type="text"
                          value=""
                          name="LNAME"
                          class=""
                          id="mce-LNAME"
                          placeholder="Last Name"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div id="mce-responses" class="clear">
                          <div
                            class="response"
                            id="mce-error-response"
                            style="display:none"
                          ></div>
                          <div
                            class="response"
                            id="mce-success-response"
                            style="display:none"
                          ></div>
                        </div>
                        {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
                        <div
                          style="position: absolute; left: -5000px;"
                          aria-hidden="true"
                        >
                          <input
                            type="text"
                            name="b_1e91ff7a96f59f0bcf06cab7f_75b4ecba83"
                            tabIndex={-1}
                            value=""
                          />
                        </div>
                        <input
                          type="submit"
                          value="Subscribe"
                          name="subscribe"
                          id="mc-embedded-subscribe"
                          class="button"
                        />
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </form>
          </div>

          {/* <!--End mc_embed_signup--> */}
          <br />
        </div>
      </div>
      <nav class="nonmobile">
        {links?.map(
          (link) =>
            "url" in link && (
              <Link activeClassName="active" href={link.url}>
                {link.label}
              </Link>
            )
        )}
        <Auth currentUser={currentUser} />
      </nav>
    </footer>
  );
};
