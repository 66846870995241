import { useEffect, useState } from "preact/hooks";
import {
  Timestamp,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "../../../firebase";
import {
  ClassNight,
  ExternalEvent,
  MailchimpAudienceSyncResult,
  NewsletterCampaign,
  NewsletterCampaignSettings,
  NewsletterContent,
  NewsletterContentBlock,
} from "../../../types";
import { Button, Input, TextareaAutosize, Tooltip } from "@mui/material";
import { renderNewsletter } from "../../../lib/newsletter/template";
import {
  getEventList,
  renderNewsletterBody,
  taughtClassNight,
  teachingClassNight,
} from "../../../lib/newsletter/render";

import style from "../../../components/forms/style.scss";
import { JSXInternal } from "preact/src/jsx";

export default function Mailchimp() {
  const [lastSyncResult, setLastSyncResult] =
    useState<MailchimpAudienceSyncResult>();
  const [campaign, setCampaign] = useState<NewsletterCampaign>();
  const [teaching, setTeaching] = useState<Record<string, string>>();
  const [events, setEvents] = useState<ExternalEvent[]>();
  const [taught, setTaught] = useState<Record<string, string>>();

  useEffect(() => {
    getDocs(
      query(
        collection(db, "import/results/mailchimp"),
        orderBy("timestamp", "desc")
      )
    ).then((querySnapshot) => {
      setLastSyncResult(
        querySnapshot.docs[0].data() as MailchimpAudienceSyncResult
      );
    });
    const classesSince = new Date();
    classesSince.setDate(classesSince.getDate() - 7);
    getDocs(
      query(
        collection(db, "class-nights"),
        where("date", ">=", Timestamp.fromDate(classesSince))
      )
    ).then((querySnapshot) => {
      querySnapshot.docs.forEach((doc) => {
        const classNight = doc.data() as ClassNight;
        if (classNight.date.toDate() < new Date()) {
          setTaught(taughtClassNight(classNight));
        } else {
          setTeaching(teachingClassNight(classNight));
        }
      });
    });
    getDoc(doc(db, "/newsletter/newsletter")).then((querySnapshot) => {
      const campaignFromDB = querySnapshot.data() as NewsletterCampaign;
      if (!campaignFromDB.settings) {
        campaignFromDB.settings = {};
      }
      if (!campaignFromDB.settings.blocks?.length) {
        campaignFromDB.settings.blocks = ["teaching"];
      }
      setCampaign(campaignFromDB);
    });
    getEventList().then(setEvents);
  }, []);

  const saveNewsletter = () => {
    setDoc(doc(db, "/newsletter/newsletter"), campaign, { merge: true });
  };

  if (!(lastSyncResult && campaign)) {
    return <>Loading...</>;
  }

  const peopleSync = () => (
    <p>
      <h3>People sync</h3>
      People & their passes last updated{" "}
      {lastSyncResult.timestamp?.toDate().toLocaleDateString("en-AU")}
      <br />
      <Tooltip
        enterTouchDelay={100}
        leaveTouchDelay={5000}
        title={lastSyncResult.added.join(", ")}
      >
        <span>
          Added {lastSyncResult.added.length} new{" "}
          {lastSyncResult.added.length === 1 ? "person" : "people"}
        </span>
      </Tooltip>{" "}
      &{" "}
      <Tooltip
        enterTouchDelay={100}
        leaveTouchDelay={5000}
        title={lastSyncResult.updated.join(", ")}
      >
        <span>
          updated {lastSyncResult.updated.length} people{" "}
          <small>(mostly remaining-uses on passes)</small>
        </span>
      </Tooltip>
      {(Object.keys(lastSyncResult.errors).length && (
        <div>
          Errors:
          {Object.keys(lastSyncResult.errors).map((email) => (
            <Tooltip
              enterTouchDelay={100}
              leaveTouchDelay={5000}
              title={lastSyncResult.errors[email].detail}
            >
              <div>
                {email} {lastSyncResult.errors[email].title}
              </div>
            </Tooltip>
          ))}
        </div>
      )) ||
        undefined}
    </p>
  );

  const serialiseHTML = (block: string) => {
    return block
      .replace(/\b((?:https?:\/\/|www\.)\S+\b)/g, "<a href='$1'>$1</a>")
      .replace(/\n/g, "<br />\n");
  };
  const deserialiseHTML = (block: string) => {
    return block.replace(/<[^>]+>/g, "");
  };

  const newsletterBlocks = (blocks: NewsletterContent[]) => {
    const blockStyle = {
      border: "3px inset grey",
      margin: "1em",
      padding: "1em",
      display: "inline-block",
      width: "calc(100% - 2em)",
    };
    const controlStyle = {
      textAlign: "right",
    };

    const setBlocks = (blocks: NewsletterContent[]) =>
      setCampaign({
        ...campaign,
        settings: { ...campaign.settings, blocks },
      });

    const setBlockTitle = (title: string, index: number) => {
      (blocks[index] as NewsletterContentBlock).title = title;
      setBlocks(blocks);
    };
    const setBlockHidden = (hidden: boolean, index: number) => {
      (blocks[index] as NewsletterContentBlock).hidden = hidden;
      setBlocks(blocks);
    };
    const setBlockBody = (body: string, index: number) => {
      (blocks[index] as NewsletterContentBlock).body = serialiseHTML(body);
      setBlocks(blocks);
    };

    const addBlockAtBtn = (index: number) => {
      const onClick = () => {
        blocks.splice(index, 0, {} as NewsletterContentBlock);
        setBlocks(blocks);
      };
      return <Button onClick={onClick}>Add block</Button>;
    };
    const removeBlockBtn = (block: NewsletterContent, index: number) => {
      if (block === "teaching" || block === "events") {
        return;
      }
      const onClick = () => {
        if (!(block.body || block.title) || confirm("Delete this block?")) {
          blocks.splice(index, 1);
          setBlocks(blocks);
        }
      };
      return <Button onClick={onClick}>Delete block</Button>;
    };
    const hideBlockBtn = (block: NewsletterContent, index: number) => {
      if (block === "teaching" || block === "events") {
        return;
      }
      const onClick = () => {
        setBlockHidden(!block.hidden, index);
      };
      return (
        <Button onClick={onClick}>
          {block.hidden ? "Un-hide" : "Hide"} block
        </Button>
      );
    };
    const moveBlockUpBtn = (index: number) => {
      if (index < 1) {
        return;
      }
      const onClick = () => {
        const tmp = blocks.splice(index, 1)[0];
        blocks.splice(index - 1, 0, tmp);
        setBlocks(blocks);
      };
      return <Button onClick={onClick}>Move up</Button>;
    };
    const moveBlockDnBtn = (index: number) => {
      if (index >= blocks.length - 1) {
        return;
      }
      const onClick = () => {
        const tmp = blocks.splice(index, 1)[0];
        blocks.splice(index + 1, 0, tmp);
        setBlocks(blocks);
      };
      return <Button onClick={onClick}>Move Down</Button>;
    };

    const renderBlock = (block: NewsletterContent, index: number) => {
      if (block === "teaching") {
        return "Teaching this week";
      } else if (block === "events") {
        return "Upcoming Events";
      } else {
        return (
          <table>
            <tbody>
              <tr>
                <th>Title</th>
                <td>
                  <Input
                    value={block.title}
                    onChange={(e) =>
                      setBlockTitle(e.currentTarget.value, index)
                    }
                    style={{ width: "100%" }}
                  />
                </td>
              </tr>
              <tr>
                <th>Body</th>
                <td>
                  <TextareaAutosize
                    minRows={4}
                    value={deserialiseHTML(block.body || "")}
                    onChange={(
                      e: JSXInternal.TargetedEvent<
                        HTMLTextAreaElement | HTMLInputElement,
                        Event
                      >
                    ) => setBlockBody(e.currentTarget.value, index)}
                    style={{ width: "100%" }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        );
      }
    };
    return (
      <>
        <div style={controlStyle}>{addBlockAtBtn(0)}</div>{" "}
        {blocks.map((block, i) => {
          const hidden = typeof block === "object" && block.hidden;
          return (
            <div>
              <div style={{ ...blockStyle, opacity: hidden ? "0.6" : "1" }}>
                {renderBlock(block, i)}
                <div style={controlStyle}>
                  {moveBlockUpBtn(i)}
                  {moveBlockDnBtn(i)}
                  {hideBlockBtn(block, i)}
                  {removeBlockBtn(block, i)}
                </div>
              </div>
              <div style={controlStyle}>{addBlockAtBtn(i + 1)}</div>
            </div>
          );
        })}
      </>
    );
  };

  const setCampaignSetting = (
    setting: keyof NewsletterCampaignSettings,
    value: string
  ) => {
    setCampaign({
      ...campaign,
      settings: { ...campaign.settings, [setting]: value },
    });
  };

  const setSendDate = (dateStr: string) => {
    const [yyyy, mm, dd] = dateStr.split("-");
    const newDate = campaign.send_time.toDate();
    newDate.setFullYear(parseInt(yyyy));
    newDate.setMonth(parseInt(mm) - 1);
    newDate.setDate(parseInt(dd));
    setCampaign({
      ...campaign,
      send_time: Timestamp.fromDate(newDate),
    });
  };

  const setSendTime = (timeStr: string) => {
    const [hh, mm] = timeStr.split(":");
    const newDate = campaign.send_time.toDate();
    newDate.setHours(parseInt(hh));
    newDate.setMinutes(parseInt(mm));
    setCampaign({
      ...campaign,
      send_time: Timestamp.fromDate(newDate),
    });
  };

  const yyyymmdd = (date: Date) => {
    return (
      date.getFullYear().toString().padStart(4, "0") +
      "-" +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0")
    );
  };

  const hhmm = (date: Date) => {
    return (
      date.getHours().toString().padStart(2, "0") +
      ":" +
      date.getMinutes().toString().padStart(2, "0")
    );
  };

  const newsletterBuilder = () => {
    const sendAt = campaign.send_time?.toDate();
    return (
      <p>
        <h3>Newsletter settings</h3>
        <p>
          Warning: Newsletter changes are scheduled overnight (when website
          changes go out), make sure to have updates done in time!
        </p>
        <table class={style.adminForm}>
          <tr>
            <th>Subject</th>
            <td>
              <Input
                value={campaign.settings?.subject_line}
                onChange={(e) =>
                  setCampaignSetting("subject_line", e.currentTarget.value)
                }
                style={{ width: "100%" }}
              />
            </td>
          </tr>
          <tr>
            <th>Preview Text</th>
            <td>
              <Input
                value={campaign.settings?.preview_text}
                onChange={(e) =>
                  setCampaignSetting("preview_text", e.currentTarget.value)
                }
                style={{ width: "100%" }}
              />
            </td>
          </tr>
          <tr>
            <th>Send Time</th>
            <td>
              <Input
                type="date"
                value={yyyymmdd(sendAt)}
                onChange={(e) => setSendDate(e.currentTarget.value)}
                style={{ width: "50%" }}
              />
              <Input
                type="time"
                value={hhmm(sendAt)}
                onChange={(e) => setSendTime(e.currentTarget.value)}
                style={{ width: "50%" }}
              />
            </td>
          </tr>
          <tr>
            <th>Content</th>
            <td>
              {newsletterBlocks(
                campaign.settings?.blocks as NewsletterContent[]
              )}
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <Button onClick={() => saveNewsletter()}>Save</Button>
            </td>
          </tr>
        </table>
      </p>
    );
  };

  const newsletterPreview = () => {
    return (
      <p>
        <h3>Newsletter preview (as mailchimp template)</h3>
        <iframe
          style={{ resize: "horizontal", height: "100vh", width: "50%" }}
          srcDoc={renderNewsletter(
            renderNewsletterBody(
              campaign.settings?.blocks,
              teaching,
              taught,
              events
            )
          ).replace(/<script[\s\S]+?<\/script>/gi, "")}
        />
      </p>
    );
  };

  return (
    <div class="app-body-wrap">
      <div class="app-body">
        <h2>Newsletters</h2>
        {peopleSync()}
        {newsletterBuilder()}
        {newsletterPreview()}
      </div>
    </div>
  );
}
